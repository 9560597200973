import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { extractLanguageSetFromProps } from '@estm/react-utils';

import { IntlProvider } from 'react-intl';

import loadableMap from './components/componentDynamicMappings';
import { getWindowRouteState } from './utils/browserUtils';

const App = (props) => {
    let routes = []; // eslint-disable-line

    Object.keys(loadableMap).forEach((loadableName) => {
        const { language } = getWindowRouteState();
        const componentProps = extractLanguageSetFromProps(props, language);

        const componentPath = `/component-render/${loadableName}`;
        const Component = loadableMap[loadableName];
        const item = (
            <Route
                key={`route::${loadableName}`}
                exact
                path={componentPath}
                render={() => <Component {...componentProps} />}
            />
        );
        routes.push(item);
    });
    return (
        <IntlProvider locale="en" messages={{}}>
            <Switch>
                {routes}
            </Switch>
        </IntlProvider>
    );
};

export {
    App,
    loadableMap,
};
