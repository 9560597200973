/**
 * Here should be listed components which have some JS dynamic/animation inside.
 * This components list will be used in the `react-server-render` project.
 */
import Loadable from 'react-loadable';

const loadableMap = {
    SomeDynamic: Loadable({
        loader: () => import(/* webpackChunkName: "SomeDynamic" */ './SomeDynamic'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./SomeDynamic')],
        modules: ['SomeDynamic'],
    }),
    //
    /* eslint-disable max-len */
    ActivityPage: Loadable({
        loader: () => import(/* webpackChunkName: "ActivityPage" */ './../design/src/components/Activities'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Activities')],
        modules: ['ActivityPage'],
    }),
    Contentfry: Loadable({
        loader: () => import(/* webpackChunkName: "Contentfry" */ './../design/src/components/Contentfry'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Contentfry')],
        modules: ['Contentfry'],
    }),
    ObjectDetailsPage: Loadable({
        loader: () => import(/* webpackChunkName: "ObjectDetailsPage" */ './../design/src/components/ObjectDetailsPage'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/ObjectDetailsPage')],
        modules: ['ObjectDetailsPage'],
    }),
    InfoCenterDetailsPage: Loadable({
        loader: () => import(/* webpackChunkName: "InfoCenterDetailsPage" */ './../design/src/components/InfoCenterDetailsPage'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/InfoCenterDetailsPage')],
        modules: ['InfoCenterDetailsPage'],
    }),
    EventBoxList: Loadable({
        loader: () => import(/* webpackChunkName: "EventBoxList" */ './../design/src/components/EventBoxList'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/EventBoxList')],
        modules: ['EventBoxList'],
    }),
    EventDetails: Loadable({
        loader: () => import(/* webpackChunkName: "EventDetails" */ './../design/src/components/Events/Details'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Events/Details')],
        modules: ['EventDetails'],
    }),
    Header: Loadable({
        loader: () => import(/* webpackChunkName: "Header" */ './../design/src/components/Header'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Header')],
        modules: ['Header'],
    }),
    HeroImage: Loadable({
        loader: () => import(/* webpackChunkName: "HeroImage" */ './../design/src/components/HeroImage'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/HeroImage')],
        modules: ['HeroImage'],
    }),
    HolidayShopTeaserBoxListThree: Loadable({
        loader: () => import(/* webpackChunkName: "HolidayShopTeaserBoxListThree" */ './../design/src/components/HolidayShopTeaserBoxListThree'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/HolidayShopTeaserBoxListThree')],
        modules: ['HolidayShopTeaserBoxListThree'],
    }),
    HolidayShopTeaserBoxListTwo: Loadable({
        loader: () => import(/* webpackChunkName: "HolidayShopTeaserBoxListTwo" */ './../design/src/components/HolidayShopTeaserBoxListTwo'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/HolidayShopTeaserBoxListTwo')],
        modules: ['HolidayShopTeaserBoxListTwo'],
    }),
    HolidayShopTeaserCardList: Loadable({
        loader: () => import(/* webpackChunkName: "HolidayShopTeaserCardList" */ './../design/src/components/HolidayShopTeaserCardList'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/HolidayShopTeaserCardList')],
        modules: ['HolidayShopTeaserCardList'],
    }),
    HotelDetails: Loadable({
        loader: () => import(/* webpackChunkName: "HotelDetails" */ './../design/src/components/Hotel/Details'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Hotel/Details')],
        modules: ['HotelDetails'],
    }),
    ImagesSlider: Loadable({
        loader: () => import(/* webpackChunkName: "ImagesSlider" */ './../design/src/components/ImagesSlider'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/ImagesSlider')],
        modules: ['ImagesSlider'],
    }),
    InfoCentersListing: Loadable({
        loader: () => import(/* webpackChunkName: "InfoCentersListLayout" */ './../design/src/components/Lists/InfoCentersListLayout'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Lists/InfoCentersListLayout')],
        modules: ['InfoCentersListing'],
    }),
    LakesListing: Loadable({
        loader: () => import(/* webpackChunkName: "LakeListLayout" */ './../design/src/components/Lists/LakesListLayout'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Lists/LakesListLayout')],
        modules: ['LakesListing'],
    }),
    LevoRecommendationBoxThree: Loadable({
        loader: () => import(/* webpackChunkName: "LevoRecommendationBoxThree" */ './../design/src/components/LevoRecommendationBoxThree'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/LevoRecommendationBoxThree')],
        modules: ['LevoRecommendationBoxThree'],
    }),
    SlopesTabsApp: Loadable({
        loader: () => import(/* webpackChunkName: "SlopesTabsApp" */ './../design/src/components/SlopesTabsApp'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/SlopesTabsApp')],
        modules: ['SlopesTabsApp'],
    }),
    TeaserBoxODAFour: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxODAFour" */ './../design/src/components/TeaserBoxODAFour'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxODAFour')],
        modules: ['TeaserBoxODAFour'],
    }),
    TeaserBoxODAImageMap812: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxODAImageMap812" */ './../design/src/components/TeaserBoxODAImageMap812'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxODAImageMap812')],
        modules: ['TeaserBoxODAImageMap812'],
    }),
    TeaserBoxODAInfoboxImage: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxODAInfoboxImage" */ './../design/src/components/TeaserBoxODAInfoboxImage'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxODAInfoboxImage')],
        modules: ['TeaserBoxODAInfoboxImage'],
    }),
    TeaserBoxODAThree: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxODAThree" */ './../design/src/components/TeaserBoxODAThree'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxODAThree')],
        modules: ['TeaserBoxODAThree'],
    }),
    SocialBreadcrumb: Loadable({
        loader: () => import(/* webpackChunkName: "SocialBreadcrumb" */ './../design/src/components/SocialBreadcrumb'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/SocialBreadcrumb')],
        modules: ['SocialBreadcrumb'],
    }),
    TeaserBoxListThree: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxListThree" */ './../design/src/components/TeaserBoxListThree'), /* eslint-disable-line */
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxListThree')],
        modules: ['TeaserBoxListThree'],
    }),
    TeaserBoxListTwo: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxListTwo" */ './../design/src/components/TeaserBoxListTwo'), /* eslint-disable-line */
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxListTwo')],
        modules: ['TeaserBoxListTwo'],
    }),
    TeaserBoxHotelsThree: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxHotelsThree" */ './../design/src/components/TeaserBoxHotelsThree'), /* eslint-disable-line */
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxHotelsThree')],
        modules: ['TeaserBoxHotelsThree'],
    }),
    TeaserBoxList: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserBoxList" */ './../design/src/components/TeaserBoxList'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserBoxList')],
        modules: ['TeaserBoxList'],
    }),
    TeaserCardList: Loadable({
        loader: () => import(/* webpackChunkName: "TeaserCardList" */ './../design/src/components/TeaserCardList'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TeaserCardList')],
        modules: ['TeaserCardList'],
    }),
    TownLanding: Loadable({
        loader: () => import(/* webpackChunkName: "TownLanding" */ './../design/src/components/TownLanding'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/TownLanding')],
        modules: ['TownLanding'],
    }),
    VideoLandscape: Loadable({
        loader: () => import(/* webpackChunkName: "VideoLandscape" */ './../design/src/components/VideoLandscape'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/VideoLandscape')],
        modules: ['VideoLandscape'],
    }),
    VideoSmall6: Loadable({
        loader: () => import(/* webpackChunkName: "VideoSmall6" */ './../design/src/components/VideoSmall6'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/VideoSmall6')],
        modules: ['VideoSmall6'],
    }),
    WebcamPage: Loadable({
        loader: () => import(/* webpackChunkName: "WebcamPage" */ './../design/src/components/WebcamPage'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/WebcamPage')],
        modules: ['WebcamPage'],
    }),
    WebcamsTabsApp: Loadable({
        loader: () => import(/* webpackChunkName: "WebcamsTabsApp" */ './../design/src/components/WebcamsTabsApp'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/WebcamsTabsApp')],
        modules: ['WebcamsTabsApp'],
    }),
    Weather: Loadable({
        loader: () => import(/* webpackChunkName: "Weather" */ './../design/src/components/Weather'),
        loading: () => null,
        webpack: () => [require.resolveWeak('./../design/src/components/Weather')],
        modules: ['Weather'],
    }),
};

export default loadableMap;
