/* eslint-disable import/prefer-default-export */
function getWindowRouteState() {
    const defaultState = {
        language: 'de',
        season: 'winter',
    };

    if (typeof window !== 'undefined') {
        const pathParts = window.location.pathname.split('/');
        return Object.assign({}, defaultState, pathParts.length ? {
            language: pathParts[1],
        } : {});
    }

    return defaultState;
}


export {
    getWindowRouteState,
};
