import 'core-js/core';
import 'core-js/shim';
import 'core-js/es5';
import 'core-js/es6';
import 'core-js/es7';
import 'core-js/fn/array';
import 'core-js/fn/json';
import 'core-js/fn/set';
import 'core-js/fn/promise';

import React from 'react';
import { hydrate, render } from 'react-dom';

import Loadable from 'react-loadable';
import { StaticRouter } from 'react-router-dom';

import { App } from './App';

const componentSelector = 'data-component';
const componentPropsSelector = 'data-props';

const nodes = document.querySelectorAll(`[${componentSelector}]`);

window.onload = () => {
    Loadable.preloadReady().then(() => {
        for (let i = 0; i < nodes.length; i += 1) {
            const rootElement = nodes[i];
            const componentName = rootElement.getAttribute(componentSelector);
            const componentProps = JSON.parse(rootElement.getAttribute(componentPropsSelector) || '{}');
            const componentRoute = componentName ? `/component-render/${componentName}` : '/component-render';

            const Application = (
                <StaticRouter context={{}} location={componentRoute}>
                    <App {...componentProps} />
                </StaticRouter>
            );

            if (!rootElement) {
                console.warn('Element not found'); // eslint-disable-line
            } else if (rootElement.hasChildNodes() === true) {
                // Loads for the SSR (already rendered)
                hydrate(Application, rootElement);
            } else {
                // Loads for the CLIENT
                render(Application, rootElement);
            }
        }
    });
};
